import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/help',
    name: 'help',
    component:  function () {
      return import(/* webpackChunkName: "about" */ '../views/HelpView.vue')
    },
  },
  {
    path: '/helptransfer',
    name: 'helptransfer',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HelpTransfer.vue')
    },
  }, {
    path: '/doubleEleven',
    name: 'doubleEleven',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/DoubleEleven.vue')
    },
  },
  {
    path: '/abouts',
    name: 'abouts',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
    },
  }, {
    path: '/agreement',
    name: 'agreement',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Agreement.vue')
    },
  }, {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
    },
  }, {
    path: '/hotboom',
    name: 'hotboom',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Hotboom.vue')
    },
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/PaySuccess.vue')
    },
  },
  {
    path: '/HomeContent',
    name: 'HomeContent',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../components/HomeContent.vue')
    },
  },
  {
    path: '*', redirect: '/'
  },
  {
    path: '/user',
    name: 'user',
    redirect: '/userInfo',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/UserView.vue')
    },
    children: [
      {
        path: '/messages',
        name: 'messages',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/messageList.vue')
        },
      },
      {
        path: '/messageDetail',
        name: 'messageDetail',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/messageDetail.vue')
        },
      },
      {
        path: '/updPass',
        name: 'updPass',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UpdPass.vue')
        },
      }, {
        path: '/updPricePass',
        name: 'updPricePass',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UpdPricePass.vue')
        },
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserInfoView.vue')
        },
      },
      {
        path: '/warehouse',
        name: 'warehouse',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/WarehouseAddress.vue')
        },
      },
      {
        path: '/footprint',
        name: 'footprint',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserFootprint.vue')
        },
      },
      {
        path: '/collect',
        name: 'collect',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserCollect.vue')
        },
      }, {
        path: '/addresslist',
        name: 'addresslist',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserAddressList.vue')
        }
      }, {
        path: '/addAddress',
        name: 'addAddress',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/addAddress.vue')
        },
      }, {
        path: '/updAddress',
        name: 'updAddress',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/updAddress.vue')
        },
      }, {
        path: '/freight',
        name: 'freight',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserFreight.vue')
        },
      },
      {
        path: '/orders',
        name: 'orders',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/OrderList.vue')
        },
      }, {
        path: '/recharge',
        name: 'recharge',
        redirect: "/rechargehome",
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserRecharge.vue')
        },
        children: [
          {
            path: '/rechargehome',
            name: 'rechargehome',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/RechargeView/RechargeHome.vue')
            },
          }, {
            path: '/recharewithdrawal',
            name: 'rechargewithdrawal',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/RechargeView/ApplicationWithdrawal.vue')
            },
          }, {
            path: '/consumptionrecord',
            name: 'consumptionrecord',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/RechargeView/ConsumptionRecord.vue')
            },
          }, {
            path: '/rechargerecord',
            name: 'rechargerecord',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/RechargeView/RechargeRecord.vue')
            },
          }, {
            path: '/withdrawalrecord',
            name: 'withdrawalrecord',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/RechargeView/WithdrawalRecord.vue')
            },
          }
        ]
      },
      {
        path: '/coupon',
        name: 'coupon',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserCoupon.vue')
        },
      },
      {
        path: '/integral',
        name: 'integral',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/UserIntegral.vue')
        },
        redirect: "/integralhome",
        children: [
          {
            path: '/integralhome',
            name: 'integralhome',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/IntegralView/IntegralHome.vue')
            },
          },
          {
            path: '/integraldetail',
            name: 'integraldetail',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/IntegralView/IntegralDetail.vue')
            },
          }
        ]
      },
      {
        path: '/waybilldetail',
        name: 'waybilldetail',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/WaybillDetail.vue')
        },
      }, ,
      {
        path: '/orderdetail',
        name: 'orderdetail',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/OrderDetail.vue')
        },
      },
      {
        path: '/logisticsdetail',
        name: 'logisticsdetail',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/User_View/LogisticsDetail.vue')
        },
      },
    ]
  },
  {
    path: '/loginregister',
    name: 'loginregister',
    redirect: '/login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/LoginRegister.vue')
    },
    children: [
      {
        path: '/login',
        name: 'LoginComponent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/Login.vue')
        }
      },
      {
        path: '/WXView',
        name: 'WXView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/WxView.vue')
        }
      }, {
        path: '/loginSuccess',
        name: 'loginSuccess',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/loginSuccess.vue')
        }
      },
      {
        path: '/register',
        name: 'RegisterComponent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/Register.vue')
        }
      }
    ]
  },
  {
    path: '/cannily',
    name: 'cannily',
    // meta: {
    //   // title: '雷石淘券券'
    //   isUseCache: false,  // 这个字段的意思稍后再说  ,
    //   keepAlive: true 
    // },
    // redirect: '/homecontent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Cannily.vue')
    },
    children: [
      {
        path: '/homecontent',
        name: 'homecontent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/HomeContent.vue')
        }
      },
      {
        path: '/categood',
        name: 'categood',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/CateView.vue')
        }
      },
      {
        path: '/goodsinfo',
        name: 'goodsinfo',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/GoodsInfo.vue')
        },
      },
    ]
  },
  {
    path: '/homemodule',
    name: 'homemodule',
    redirect: "/shoppingcar",
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HomeModules.vue')
    },
    children: [
      {
        path: '/commitwaybill',
        name: 'commitwaybill',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/IntegratedModules/CommitWaybill.vue')
        },
      }, {
        path: '/shoppingcar',
        name: 'shoppingcar',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/IntegratedModules/shoppingcar.vue')
        },
      }, {
        path: '/settlementView',
        name: 'SettlementView',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/IntegratedModules/SettlementView.vue')
        },
      }, {
        path: '/payment',
        name: 'payment',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/IntegratedModules/PaymentView.vue')
        },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
