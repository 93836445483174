<template>
  <div class="home-box">
    <div class="content-wrapper">
      <div class="bg-imgbox">
        <img src="../assets/img/home_bag_img1.png" width="100%" alt="" />
        <img class="img-text" src="../assets/img/homeslogan.png" alt="" />
        <div class="abs-box">
          <span>请将商品链接粘贴于此，或直接搜商品名称</span>
          <div class="search">
            <el-input
              placeholder=""
              v-model="href_url"
              style="height: 70px; margin-right: 12px"
            >
              <template slot="prepend">http://</template>
            </el-input>
            <button class="btn" @click="SelectGood()">嗖一下</button>
          </div>
          <span class="search-info">淘宝 天猫 京东 拼多多 全网商品全覆盖</span>
        </div>
      </div>
      <div class="flow-box">
        <div class="item">
          <div>
            <img src="../assets/img/cj1_transaction.png" alt="" />
          </div>

          <span class="title">提交订购</span>
          <span class="text">仅支付商品费用</span>
        </div>
        <div class="item">
          <div>
            <img src="../assets/img/shopping_buy.png" alt="" />
          </div>
          <span class="title">西速采购</span>
          <span class="text">核对称重入库</span>
        </div>
        <div class="item">
          <div>
            <img src="../assets/img/reception_receive.png" alt="" />
          </div>
          <span class="title">验货入库</span>
          <span class="text">收到商品，开箱验货</span>
        </div>
        <div class="item">
          <div>
            <img src="../assets/img/travel_journey.png" alt="" />
          </div>
          <span class="title">打包集运</span>
          <span class="text">支付运费打包集运</span>
        </div>
        <div class="item">
          <div>
            <img src="../assets/img/_box2x.png" alt="" />
          </div>
          <span class="title">用户收货</span>
          <span class="text">代购完成</span>
        </div>
      </div>
      <div class="dedicated-box">
        <div class="dedicated-circuit">
          <div class="title">运费预估，西速物流专线</div>
          <div class="site-list">
            <div class="site-item" @click="tofreight('澳大利亚')">
              <div class="site-img">
                <img src="../assets/img/Australia.png" alt="" />
              </div>
              <div class="site-info">
                <div class="site-title">西速特快</div>
                <div class="info-box">
                  <div class="info-item">
                    <span>最快3-7天</span><span>时效工作日</span>
                  </div>
                  <div class="info-item">
                    <span>¥22.00</span><span>续重0.5kg</span>
                  </div>
                  <div class="info-item">
                    <span>¥64.00起</span><span>首重1kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="site-item" @click="tofreight('美国')">
              <div class="site-img">
                <img src="../assets/img/America.png" alt="" />
              </div>
              <div class="site-info">
                <div class="site-title">西速特快</div>
                <div class="info-box">
                  <div class="info-item">
                    <span>最快3-7天</span><span>时效工作日</span>
                  </div>
                  <div class="info-item">
                    <span>¥36.00</span><span>续重0.5kg</span>
                  </div>
                  <div class="info-item">
                    <span>¥83.00起</span><span>首重1kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="site-item" @click="tofreight('法国')">
              <div class="site-img">
                <img src="../assets/img/Europe.png" alt="" />
              </div>
              <div class="site-info">
                <div class="site-title">西速特快</div>
                <div class="info-box">
                  <div class="info-item">
                    <span>最快3-7天</span><span>时效工作日</span>
                  </div>
                  <div class="info-item">
                    <span>¥32.00</span><span>续重0.5kg</span>
                  </div>
                  <div class="info-item">
                    <span>¥88.00起</span><span>首重1kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="site-item" @click="tofreight('日本')">
              <div class="site-img">
                <img src="../assets/img/Japan.png" alt="" />
              </div>
              <div class="site-info">
                <div class="site-title">西速特快</div>
                <div class="info-box">
                  <div class="info-item">
                    <span>最快3-7天</span><span>时效工作日</span>
                  </div>
                  <div class="info-item">
                    <span>¥19.00</span><span>续重0.5kg</span>
                  </div>
                  <div class="info-item">
                    <span>¥79.00起</span><span>首重1kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="XISU_Server">
        <div class="title">西速，海外华人网购全链路服务</div>
        <div class="tab-box">
          <div class="tab-item" v-for="(ele, len) in tabs" :key="len">
            <div
              @click="tabClick(ele)"
              :class="activeTab == ele ? 'active' : ''"
            >
              {{ ele }}
            </div>
          </div>
        </div>
        <div class="items">
          <div class="item" v-show="activeTab == '专业'">
            <div class="info-box">
              <div class="info-title">选择西速更专业</div>
              <div class="info">
                一站式为海外用户代购中日韩电商平台商品。多种商品一键送货上门
                ，超低价配送全球200多个国家， 让您在海外也能轻松购买中日韩商品。
              </div>
            </div>

            <div>
              <img src="../assets/img/_IGP6259A.png" alt="" />
            </div>
          </div>
          <div class="item" v-show="activeTab == '安全'">
            <div class="info-box">
              <div class="info-title">选择西速更安全</div>
              <div class="info">
                下单8小时内完成定购，8小时内完成质检并打包，180天超长费仓储，最快3天送达。订单
                裹物流进程一目了然，让您及时了 解包裹动向。
              </div>
            </div>
            <div>
              <img src="../assets/img/IST_23395_01192.png" alt="" />
            </div>
          </div>
          <div class="item" v-show="activeTab == '效率'">
            <div class="info-box">
              <div class="info-title">选择西速更高效</div>
              <div class="info">
                实时跟进客户购买需求,专属专家 7x24 小时全程对接客户商品
                询-采购-寄送-物流跟踪等服务
              </div>
            </div>
            <div>
              <img src="../assets/img/BVS-P0161043.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-dialog
      title="微信客服"
      :visible.sync="CallVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div class="calls">
        <div class="call-box" v-for="(ele, index) in calls" :key="index">
          <div>
            <img :src="ele.qrCode2" alt="" />
          </div>
          <div class="call-btn" @click="copyWC(ele.wechat)">复制微信号</div>
        </div>
      </div>

      <div class="call-info">
        <div class="">
          <label for="">方法一：</label>
          <span>1、点击复制微信号 2、粘贴至微信并添加</span>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template> 

<script>
export default {
  data() {
    return {
      href_url: "",
      activeTab: "专业",
      tabs: ["专业", "安全", "效率"],
      // userInfo:
      //   JSON.stringify(this.$store.getters.getUserInfo) == "{}"
      //     ? null
      //     : this.$store.getters.getUserInfo,
      // tab: [
      //   { name: "首页", href: "/", active: "active" },
      //   { name: "帮我转运", href: "/helptransfer" },
      //   { name: "帮我买", href: "https://www.eztao.net", type: "_blank" },
      //   { name: "西速用心选", href: "/cannily" },
      // ],
      // cartNum: 0,
    };
  },
  async created() {
    let _this = this;
    let num = 0;
    setInterval(() => {
      if( _this.tabs.length<=num){
        num=0
      }
      _this.activeTab = _this.tabs[num++]
    }, 3000);
  },
  methods: {
    tofreight(name) {
      this.$router.push("/help?nation=" + name);
    },
    tabClick(name) {
      this.activeTab = name;
    },
    async SelectGood() {
      let url = unescape(unescape(this.href_url));
      try {
        let { data } = await this.$http.get(
          "https://tool.eztao.net/api/Goods?_url=" + encodeURIComponent(url)
        );
        console.log(data);
        this.$router.push({
          path: "/goodsinfo",
          query: {
            _url: encodeURIComponent(url),
          },
        });
      } catch (error) {
        this.$message.error("该链接找不到对应商品");
      }
    },

    // handleClose() {
    //   this.CallVisible = false;
    // },
  },
};
</script>

<style scoped>
.XISU_Server .info-box {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.XISU_Server .info-box .info-title {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.img-text {
  position: absolute;
  top: 45%;
  left: 15%;
  width: 28%;
  z-index: 99;
  height: auto !important;
}
.dedicated-box {
  padding: 50px 0;
  background: #f7f7f7;
}
.dedicated-circuit {
  width: 1350px;
  margin: 0 auto;
}
.dedicated-circuit img {
  width: 300px;
  height: 295px;
}
.site-list {
  display: flex;
  justify-content: space-between;
}
.site-item {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 15px;
  overflow: hidden;
  transition: transform .5s ;
}
.site-item:hover{
  transform: translateY(-5px);
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
} 
.site-item .site-title {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-bottom: 15px;
}
.site-item .info-box {  
  display: flex;
  justify-content: space-between;
}
.site-info .info-box .info-item {
  display: flex;
  flex-direction: column;
  position: relative;
}
.site-info .info-box .info-item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -25%;
  height: 70%;
  top: 15%;
  border: 1px solid #979797;
}
.site-list .info-item span:first-child {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #6b6b6b;
}
.site-list .info-item span:last-child {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  font-weight: 600;
  color: #606060;
}
.bg-imgbox >>> .el-input__inner {
  height: 70px;
}
.home-box >>> .el-dialog__body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.calls {
  display: flex;
  justify-content: center;
}
.call-box {
  padding: 25px 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.call-box img {
  width: 300px;
  height: 300px;
}
.call-box .call-btn {
  color: #8a00ff;
  border: 1px solid #8a00ff;
  border-radius: 20px;
  width: 100px;
  padding: 4px 0;
  margin-top: 15px;
  cursor: pointer;
}
.content-wrapper {
  position: relative;
}
.content-wrapper .bg-imgbox {
  position: relative;
}
.content-wrapper .bg-imgbox img {
  height: 715px;
  object-fit: cover;
}
.content-wrapper .bg-imgbox .abs-box {
  position: absolute;
  z-index: 10;
  top: 53%;
  left: 50%;
  transform: translateY(-50%);
  text-align: left;
}
.content-wrapper .bg-imgbox .abs-box div.search {
  padding: 8px 0;
  display: flex;
  width: 570px;
  align-items: center;
}
.abs-box div .btn {
  width: 119px;
  height: 70px;
  background: #8a00ff;
  border-radius: 2px;
  color: #fff;
}
.abs-box span {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
}
.abs-box >>> .el-input-group__prepend {
  color: #333;
  font-size: 18px;
}
.flow-box {
  display: flex;
  width: 1350px;
  margin: 0 auto;
  padding: 35px 0 125px;
  justify-content: center;
}
.flow-box .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 270px;
  position: relative;
  width: 165px;
}
.flow-box .item:not(:last-child) {
  padding-right: 80px;
}
.flow-box .item:not(:last-child) > div::after {
  content: ".....";
  color: #e2c5ff;
  position: absolute;
  font-size: 38px;
  right: 10px;
  top: 60px;
}
.flow-box .item img {
  width: 42px;
  object-fit: cover;
}
.flow-box .item div {
  background: #fcf9ff;
  overflow: hidden;
  border-radius: 50%;
  width: 165px;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flow-box .item .title {
  margin: 11px 0;
  color: #333;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #2d2d2d;
}
.flow-box .item .text {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.XISU_Server {
  width: 1350px;
  margin: 0 auto;
  padding-bottom: 120px;
  padding-top: 45px;
}
.search-info {
  color: #9f36f6 !important;
  font-weight: 600 !important;
}
.XISU_Server .title,
.dedicated-circuit .title {
  font-size: 42px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4c4c4c;
  padding-bottom: 55px;
  text-align: left;
}
.XISU_Server .tab-box {
  display: flex;
  justify-content: space-around;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-bottom: 30px;
}
.tab-box .tab-item > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.tab-box .tab-item .active::after {
  content: "";
  width: 90%;
  margin: 0 auto;
  background: #8229cd;
  height: 6px;
  border-radius: 3px;
}
.XISU_Server .items {
  display: flex;
}
.XISU_Server .items .item {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* width: 350px;
  height: 440px; */
  /* flex-direction: column; */
}
.XISU_Server .items .item img {
  width: 680px;
  height: 450px;
  object-fit: cover;
}
.XISU_Server .items .item .info {
  padding: 22px 0;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4c4c4c;
  width: 280px;
  /* background: #fafafa; */
}
/* ________________________________________________________- */
footer {
  background: #2e3033;
  padding: 35px 0;
}
footer .container {
  width: 1350px;
  margin: 0 auto;
}
.footer-service,
.footer-links {
  display: flex;
  justify-content: space-around;
  padding-bottom: 35px;
  border-bottom: 1px solid #979797;
}
.footer-service .item {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
}
.footer-service .item .title {
  margin-bottom: 8px;
}
.footer-service .item .text {
  font-size: 19px;
}
.footer-links {
  display: flex;
  color: #fff;
  padding-top: 25px;
}
.footer-links .col {
  display: flex;
  flex-direction: column;
}
.footer-links .section {
  margin-bottom: 22px;
}
.col li {
  padding: 5px 0;
}
.col li a {
  color: #ffffff;
  font-size: 12px;
  text-align: left;
  display: block;
}
.footer-links .logo-box {
  display: flex;
  flex-direction: column;
  width: 106px;
}
.logo-box span {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.site-info {
  padding: 15px 17px 25px;
  color: #ffffff;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
</style>
