import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import $http from '@/util/request'
import '@/assets/css/iconfont.css'
import '@/assets/css/iconfont.ttf'
import '@/assets/css/iconfont.woff'
import '@/assets/css/iconfont.woff2'
import "vue-virtual-scroller/dist/vue-virtual-scroller.css" // 引入它的 css
import VueVirtualScroller from "vue-virtual-scroller" // 引入它
Vue.use(VueVirtualScroller) // use 它
Vue.prototype.$http = $http;
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
