import axios from "axios";
import NProgress from "nprogress";
import { showFullScreenLoading, hideFullScreenLoading } from "@/util/loading";
import Vue from 'vue'
import router from "@/router";
const CANCEL_TOKEN = axios.CancelToken;
const MAX_CANCEL_TOKEN_NUM = 2000;
Vue.prototype.$httpRequestList = [];
let flag = true;

NProgress.configure({
    showSpinner: false
})
const $http = axios.create({
    timeout: 10000,
});
$http.interceptors.request.use(
    config => {
        showFullScreenLoading();
        config.CancelToken = new CANCEL_TOKEN(c => {
            if (Vue.prototype.$httpRequestList.length == MAX_CANCEL_TOKEN_NUM) {
                Vue.prototype.$httpRequestList.pop();
            }
            Vue.prototype.$httpRequestList.unshift(c);
        })
        console.log(config.url);
        let white = ['/Auth/Token', '/Account/Register'];
        if (white.indexOf(config.url) == -1) {
            NProgress.start();
            let user = localStorage.getItem("userToken");
            if (!user) {
                localStorage.removeItem("userInfo");
                localStorage.removeItem("userToken");
            } else {
                config.headers.Authorization = localStorage.getItem("authorizationScheme") + " " + user;
            }
        }

        return config;
    },
    error => {
        hideFullScreenLoading();
        return Promise.reject(error);
    }
);
$http.interceptors.response.use(
    response => {
        NProgress.done();
        if (response.status != 200) {
            return false;
        }
        hideFullScreenLoading();
        return response;
    },
    error => {
        hideFullScreenLoading();
        console.log(error);
        if (error.response.status == 401 && flag && error.response.data.code == "4001") {
            console.log(error);
            flag = false;
            localStorage.removeItem("userInfo");
            localStorage.removeItem("userToken");
            localStorage.removeItem("tokenTime");
            router.afterEach(function (to, from) {
                Vue.prototype.$httpRequestList.forEach((cancel) => {
                    cancel();
                });
            });
            location.href = "#/login?_url=" + window.encodeURIComponent(location.href);
            return;
        }

        return Promise.reject(error);
    }
);
export default $http