// import Vue from "vue";
import { Loading } from "element-ui";
 
let loadOn;
let loadingCount = 0;
function startLoading(target) {
  const options = {
    lock: true,
    text: "Loading...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
    target: document.querySelector(target),//target
  };
  loadOn = Loading.service(options);
  return loadOn;
}
 
function endLoading() {
  loadOn.close();
}
 
export function showFullScreenLoading(target) {
  if (loadingCount=== 0) {
    startLoading(target);
  }
  loadingCount++;
}
export function hideFullScreenLoading() {
  if (loadingCount<= 0) return;
  loadingCount--;
  if (loadingCount=== 0) {
    endLoading();
  }
}