import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:null,
    settlementList:[]
  },
  getters: {
    getSettlementList(state){
      return state.settlementList
    },
    getUserInfo(state){
      return state.userInfo;
    }
  },
  mutations: {
    setUserInfo(state,payload){ 
      return state.userInfo = payload;
    },
    setSettlementList(state,payload){
      return state.settlementList = payload;
    }
  },
  actions: {
    userInfo(context,payload){
      context.commit("setUserInfo",payload);
    },
    settlement(context,payload){
      console.log(payload);
      sessionStorage.setItem("setSettlementList",JSON.stringify(payload))
      context.commit("setSettlementList",payload);
    }
  },
  modules: {
  }
})
