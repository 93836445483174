<template>
  <div id="app">
    <header>
      <div class="logo-imgbox">
        <router-link to="/">
          <img src="./assets/img/logo1.png" width="55" alt="" />
        </router-link>
      </div>
      <!-- <home-tab :hometab="tab" @getTab="getTab"></home-tab> -->
      <div class="tab-items">
        <ul class="items">
          <li v-for="(ele, index) in tab" :key="index" class="tab-item">
            <a v-if="ele.type" :target="ele.type" :href="ele.href">{{
              ele.name
            }}</a>
            <router-link
              @click.native="cilStyle($event)"
              v-else
              :class="ele.active"
              :data-id="ele.name"
              :to="ele.href"
              replace
              >{{ ele.name }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="topbar">
        <div v-if="userInfo" class="user-status" @click="userCli()">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" />
          <img v-else src="./assets/img/userimg.png" />
          <span class="name">{{ userInfo.name }}</span>

          <div class="ul-menu"></div>
        </div>
        <div v-else class="user-status" @click="userCli()">
          <span class="userNull">登录/注册</span>
        </div>
        <div class="shopping-car" v-show="userInfo != null">
          <router-link to="/shoppingcar">
            <span class="iconfont icon-gouwuche"></span>
            <span class="num">{{ cartNum }}</span></router-link
          >
        </div>
        <div class="call center" @click="gocall()">
          <span class="iconfont icon-kefu"></span>
        </div>
        <div class="mobile iconfont icon-app" @click="goapp()"></div>
        <!-- <div class="language">
          <span class="iconfont icon-diqiuyi_earth"></span>
        </div> -->
      </div>
    </header>
    <router-view />
    <footer class="footer">
      <div class="container">
        <div class="footer-service">
          <div class="item">
            <div class="title">客服热线电话</div>
            <div class="text">+86-400-800-9533</div>
          </div>
          <div class="item">
            <div class="title">服务专线信箱</div>
            <div class="text">service@eztao.net</div>
          </div>
          <div class="item">
            <div class="title">投诉建议信箱</div>
            <div class="text">wangyunan@eztao.net</div>
          </div>
        </div>
        <div class="footer-links">
          <div class="col col1">
            <span class="section">购物指南</span>
            <ul>
              <li><a href="">什么是代购</a></li>
              <li><a href="">代购流程图</a></li>
              <li><a href="">复制商品链接</a></li>
            </ul>
          </div>
          <div class="col col2">
            <span class="section">购物指南</span>
            <ul>
              <li><a href="">什么是转运</a></li>
              <li><a href="">查看转运商品</a></li>
              <li><a href="">西速代验货</a></li>
            </ul>
          </div>
          <div class="col col3">
            <span class="section">支付方式</span>
            <ul>
              <li><a href="">微信支付</a></li>
              <li><a href="">支付宝支付</a></li>
              <li><a href="">paypal支付</a></li>
            </ul>
          </div>
          <div class="col col4">
            <span class="section">关于我们</span>
            <ul class="submenu">
              <li><router-link to="/abouts">公司介绍</router-link></li>
              <li><router-link to="/agreement">服务协议</router-link></li>
              <li><router-link to="/privacy">用户隐私声明</router-link></li>
            </ul>
          </div>
          <div class="col col5">
            <span class="section">用户须知</span>
            <ul class="submenu">
              <li><a href="">用户隐私声明</a></li>
              <li><a href="">用户转运条款</a></li>
              <li><a href="">仓库地址</a></li>
            </ul>
          </div>
          <div class="logo-box">
            <img src="./assets/img/WechatIMG11.png" alt="" />
            <span>西速全球购小程序</span>
          </div>
        </div>
        <div class="site-info">
          Copyright©2012-2022 superbuy.com All Rights
          Reserved粤ICP备20220802号|营业执照|主办单位：上海西速电子商务有限公司
        </div>
      </div>
    </footer>
    <div class="toolkit-box">
      <div class="toolkit-list">
        <div class="toolkit-item">
          <span class="text iconfont icon-kefu" @click="gocall()"></span>
        </div>
        <div
          class="toolkit-item"
          @mouseout="floatingOut($event)"
          @mouseover="floatingMove($event)"
        >
          <span class="text iconfont icon-app"></span>
          <div class="floating-box" style="display: none">
            <div class="code">
              <img src="./assets/img/app.png" alt="" />
              <span>app</span>
            </div>
            <div class="code">
              <img src="./assets/img/小程序.png" alt="" />
              <span>小程序</span>
            </div>
          </div>
        </div>
        <div
          class="toolkit-item"
          @mouseout="floatingOut($event)"
          @mouseover="floatingMove($event)"
        >
          <span class="text iconfont icon-weixin_wechat"></span>
          <div class="floating-box wx" style="display: none">
            <div class="code">
              <img src="./assets/img/微信二维码.png" alt="" />
              <span>微信客服</span>
            </div>
          </div>
        </div>
        <div class="toolkit-item">
          <span class="text" @click="helptransferView()">帮我<br />转运</span>
        </div>
      </div>
    </div>
    <!-- <el-dialog
      title=""
      :visible.sync="isshow"
      width="60%"
      :before-close="handleClose"
    >
      <div class="bulletin-board">
        <img src="./assets/img/Activity2.png" alt="" />
        <div class="btn-save" @click="getActivity()">立即领取</div>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
// import HomeTab from "@/components/XISU_Tab.vue";
export default {
  data() {
    return {
      cartNum: 0,
      num: 0,
      tab: [
        { name: "首页", href: "/", active: "active" },
        { name: "帮我买", href: "/cannily" },
        { name: "西速用心选", href: "/HomeContent" },
        { name: "帮我集运", href: "/helptransfer" },
        { name: "玩转代购", href: "/hotboom" },
      ],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      isshow: false,
    };
  },
  async created() {
    if (this._isMobile()) {
      window.location.href = "#/h5.html";
    }
    // if (window.performance.navigation.type == 1) {
    console.log("页面被刷新");
    // } else {
    //   this.isshow = true;
    // }
    if (window.name == "") {
      console.log("首次被加载");
      // 在首次进入页面时我们给window.name设置一个固定值(isRefresh)
      this.isshow = true;
      window.name = "isRefresh";
    } else if (window.name == "isRefresh") {
      console.log("页面被刷新");
    }
    // if (!document.cookie.includes("ad=")) {
    //   this.setcookie();
    //   this.isshow = true;
    // } else {
    //   this.isshow = false;
    // }
    let tokenTime = localStorage.getItem("tokenTime");
    if (tokenTime) {
      if (tokenTime - parseInt(new Date().getTime() / 1000) <= 0) {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userToken");
        localStorage.removeItem("tokenTime");
        // this.$router.push("/login")
      }
    }
    let ui = localStorage.getItem("userInfo");
    if (ui) {
      this.$store.dispatch(
        "userInfo",
        JSON.parse(localStorage.getItem("userInfo"))
      );
      this.userInfo = JSON.parse(ui);
      let { data } = await this.$http.post(
        "http://trade.eztao.net/api/ShoppingCart/GetShoppingCartCount"
      );
      this.cartNum = data.body;
    }
  },
  watch: {
    // 路由变化就是刷新数据，为了解决子级跳转到这个页面，数据不刷新问题
    $route: {
      handler() {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (
          this.$route.name == "privacy" ||
          this.$route.name == "agreement" ||
          this.$route.name == "abouts"
        ) {
          let tab = [
            { name: "关于我们", href: "/abouts" },
            { name: "服务协议", href: "/agreement" },
            { name: "隐私声明", href: "/privacy" },
            // { name: "网站地图", href: "/" },
            { name: "返回西速首页", href: "/" },
          ];
          tab.forEach((element) => {
            element.active = "";
            if (element.href == this.$route.path) {
              element.active = "active";
              // this.$emit("getTab", { tab: this.hometab});
            }
          });
          this.tab = tab;
        } else {
          let tab = [
            { name: "首页", href: "/", active: "active" },
            { name: "帮我买", href: "/cannily" },
            { name: "西速用心选", href: "/HomeContent" },
            { name: "帮我集运", href: "/helptransfer" },
            { name: "玩转代购", href: "/hotboom" },
          ];
          tab.forEach((element) => {
            element.active = "";
            if (element.href == this.$route.path) {
              console.log(
                this.$route,
                element.href,
                element.href == this.$route.path
              );
              element.active = "active";
              return;
              // this.$emit("getTab", { tab: this.hometab});
            }
          });
          this.tab = tab;
        }

        //   this.tab= [
        //   { name: "首页", href: "/", active: "active" },
        //   { name: "西速用心选", href: "/cannily" },
        //   { name: "帮我集运", href: "/helptransfer" },
        //   { name: "玩转代购", href: "/hotboom" },
        // ]
      },
      immediate: true,
    },
  },
  updated() {
    this.tab.forEach((element) => {
      if (element.active && element.href == this.$route.path) {
        return;
      }
      element.active = "";
      if (element.href == this.$route.path) {
        if (!element.active) {
          element.active = "active";
        }
      }
    });
    let tokenTime = localStorage.getItem("tokenTime");
    if (tokenTime) {
      if (tokenTime - parseInt(new Date().getTime() / 1000) <= 0) {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userToken");
        localStorage.removeItem("tokenTime");
        // this.$router.push("/login")
      }
    }
    let userInfo = localStorage.getItem("userInfo");
    let userToken = localStorage.getItem("userToken");
    if (!userInfo || !userToken) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userToken");
      localStorage.removeItem("tokenTime");
      this.$store.dispatch("userInfo", null);
      this.$route.matched.forEach((element) => {
        if (
          element.name == "homemodule" ||
          element.name == "user" ||
          element.name == "login"
        ) {
          this.$router.push("/login");
        }
      });
    }
  },
  methods: {
    getActivity() {
      this.isshow = false;
      this.$router.push("/doubleEleven");
    },
    handleClose() {
      this.isshow = false;
    },
    setcookie() {
      let d = new Date();
      // d.setTime(d.getTime() + 24 * 60 * 60 * 1000);//24小时
      // d.setTime(d.getTime() + 1 * 60 * 60 * 1000);//1小时
      d.setTime(d.getTime() + 12 * 60 * 1000);
      // ad=popup-ad   键值对形式：name=key   expires 有效期
      document.cookie = "ad=popup-ad;expires= " + d.toGMTString();
      let result = document.cookie;
      return result;
    },
    helptransferView() {
      this.$router.push("/helptransfer");
    },
    floatingOut(e) {
      setTimeout(() => {
        e.path[1].querySelector(".floating-box").style.display = "none";
      }, 300);
    },
    floatingMove(e) {
      setTimeout(() => {
        e.path[1].querySelector(".floating-box").style.display = "flex";
      }, 300);
    },
    cilStyle(e) {
      if (e.target.getAttribute("data-id")) {
        this.tab.forEach((element) => {
          element.active = "";
          if (element.name == e.target.getAttribute("data-id")) {
            element.active = "active";
            // this.$emit("getTab", { tab: this.hometab});
          }
        });
        this.tab = Object.assign([], this.tab);
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    userCli() {
      if (this.userInfo) {
        this.$router.push("/user");
      } else {
        this.$router.push("/login");
      }
    },
    goapp() {
      window.open("https://www.eztao.net", "_blank");
    },
    async gocall() {
      // let { data } = await this.$http.post(
      //   "https://trade.eztao.net/api/WechatCS/GetAll"
      // );
      // this.calls = data.list;
      // this.CallVisible = true;
      // var _hmt = _hmt || [];
      // (function () {
      //   var hm = document.createElement("script");
      //   hm.src = "https://hm.baidu.com/hm.js?95383ebf67ed50a595991485a07f0943";
      //   var s = document.getElementsByTagName("script")[0];
      //   s.parentNode.insertBefore(hm, s);
      // })();
      window.open(
        "https://p.qiao.baidu.com/cps/chat?siteId=18486022&userId=41777772&siteToken=f7019f51b55ed3c2182af2eef39e9b70",
        "_blink"
      );
    },
    copyWC(text) {
      var input = document.createElement("textarea"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
  },
  // components: {
  //   HomeTab,
  // },
};
</script>


<style lang="scss">
#newBridge .icon-right-center {
  top: 88% !important;
}
img {
  object-fit: contain;
}
* {
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  outline: none;
  font: inherit;
  vertical-align: baseline;
}
html {
  overflow-y: scroll;
}
a {
  text-decoration: none;
}
:root {
  overflow-y: auto;
  overflow-x: hidden;
}
ul,
ol {
  list-style: none;
}
:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
header {
  display: flex;
  justify-content: space-between;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  max-height: 100px;
  min-height: 62px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}
header > div:nth-child(2) {
  flex: 1;
}
.logo-imgbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px 0 64px;
}
.topbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 64px;
}
.topbar > div {
  padding: 8px;
}
.topbar > div,
.topbar > div a {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #333;
}
.topbar .iconfont {
  color: #333;
  font-size: 16px;
}
.topbar .user-status {
  display: flex;
  align-items: center;
}
.topbar .user-status img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.user-status .userNull {
  color: #8a00ff;
  border: 1px solid #8a00ff;
  border-radius: 15px;
  display: flex;
  padding: 3px 8px;
}
.topbar .user-status .name {
  display: block;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.topbar .shopping-car .num {
  margin-left: 3px;
}
footer {
  background: #2e3033;
  padding: 35px 0;
}
footer .container {
  width: 1350px;
  margin: 0 auto;
}
.footer-service,
.footer-links {
  display: flex;
  justify-content: space-around;
  padding-bottom: 35px;
  border-bottom: 1px solid #979797;
}
.footer-service .item {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
}
.footer-service .item .title {
  margin-bottom: 8px;
}
.footer-service .item .text {
  font-size: 19px;
}
.footer-links {
  display: flex;
  color: #fff;
  padding-top: 25px;
}
.footer-links .col {
  display: flex;
  flex-direction: column;
}
.footer-links .section {
  margin-bottom: 22px;
}
.col li {
  padding: 5px 0;
}
footer .col li a {
  color: #ffffff;
  font-size: 12px;
  text-align: left;
  display: block;
}
.footer-links .logo-box {
  display: flex;
  flex-direction: column;
  width: 106px;
}
footer .logo-box span {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
footer .site-info {
  padding: 25px 0 0;
  color: #ffffff;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.tab-items {
  max-height: 120px;
  position: relative;
  padding: 0 20px;
}
.tab-items .items {
  display: flex;
  min-width: 600px;
  justify-content: flex-start;
  height: 100%;
}
.tab-item {
}
.tab-item a {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.tab-item a.active {
  color: #8a00ff;
  background: #fff;
}
.toolkit-box {
  position: fixed;
  right: 20px;
  bottom: 200px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-bottom: none;
  z-index: 9999;
  border-radius: 10px;
  // overflow: hidden;
}
.toolkit-list {
  display: flex;
  flex-direction: column;
}
.toolkit-list .toolkit-item {
  width: 48px;
  height: 48px;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  position: relative;
}
.toolkit-list .toolkit-item:hover {
  color: #fff;
  background: #8a00ff;
}
.toolkit-item .text {
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  // font-weight: 600;
}
.toolkit-item .text.iconfont {
  font-size: 18px;
}
.floating-box {
  position: absolute;
  left: 0;
  display: flex;
  top: 0;
  left: -182px;
  color: #333 !important;
  border: 1px solid #ebebeb;
}
.floating-box.wx {
  left: -90px;
}
.floating-box img {
  width: 90px;
  height: 90px;
}
.floating-box .code {
  display: flex;
  flex-direction: column;
}
.bulletin-board {
  display: flex;
  flex-direction: column;
}
.btn-save {
  width: 240px;
  height: 46px;
  background: #8a00ff;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0;
  cursor: pointer;
}
.btn-save.off {
  background: #ccc;
}
.el-dialog {
  background: none !important;
  box-shadow: none !important;
}
.el-dialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  font-size: 24px;
}
</style>
